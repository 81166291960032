import * as styles from "./styles";
import { IQuestion, IAnswer } from '../../Models/IReport.model';

const ExcelJS = require('exceljs');
const FileSaver = require('file-saver');

//answer: IQuestion
const ExcelReport = {
    header(cell: any) {
        cell.height = 20;
        cell.font = { bold: true }; //size: 12
        cell.alignment = { ...cell.alignment, ...styles.alignVMiddle };
    },

    async report(info: any, state: any, sheet: string) {
        const muId = info.muId !== undefined ? info.muId : '';
        const columnsProfile = [
            { /* header: "",  */key: "name", width: 20 }, //username
            { key: "value", width: 30 },   //muId
            { key: "answer", width: 200 },   //muId
        ];
        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet(sheet);
        ws.columns = columnsProfile;
        ws.addRow({ name: 'ชื่อ-นามสกุล', value: info.username });
        ws.addRow({ name: 'รหัส', value: muId });
        ws.addRow({ name: 'ครั้งที่', value: state.id });
        ws.addRow({ name: 'เวลาเริ่มเล่น', value: state.time_start.toLocaleString() });
        ws.addRow({ name: 'เวลาสิ้นสุด', value: state.time_finish.toLocaleString() });
        ws.addRow({ name: 'ระยะเวลาเล่น(นาที)', value: state.time_total });
        ws.addRow({ name: 'คะแนน', value: state.score });
        ws.getColumn(2).alignment = {
            ...styles.alignHLeft,
            ...styles.alignVMiddle,
        };

        ws.addRow({});
        ws.addRow({});
        ws.addRow({ name: 'ข้อที่', value: '' });
        this.header(ws.getRow(10));
        //data.data(state.data)
        state.data.forEach((answer: IQuestion, index: number) => {
            if (answer) {
                ws.addRow({ name: `${answer.qid}`, value: `คำถาม`, answer: answer.qus });
                answer.ans.forEach((ans: IAnswer, index: number) => {
                    const arr_a = ans.a.split(", ");

                    ws.addRow({ name: ``, value: ``, answer: `#${index + 1}  ${arr_a}` });
                    if (ans.r.search('false') < 0) {
                        //ถูกหมด
                        const cell = ws.getCell(`C${ws.rowCount}`);
                        cell.font = { color: { argb: 'FF36BF53' } }
                    }
                })
            }
            ws.addRow({});
        })

        const buff = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(
            new Blob([buff]),
            `DNS ${info.username}-${sheet} ครั้งที่${state.id}.xlsx`
        );
        return workbook;
    }
}

export default ExcelReport;